/** root url */
export const AppURL = {
    account: 'Account',
    authen: 'Authen'
};

/** api path url */
export const ApiPathURL = {
    account: 'rest-account',
    contact: 'rest-contact',
    notification: 'rest-notification'
};
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AppConfig } from './configs/app.config';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppConfig implements OnInit {

  constructor(
    private _primeNg: PrimeNGConfig,
    private _auth: AuthService,
  ) {
    super();
    // โหลดข้อมูลผู้ใช้งานมาตั้งแต่เริ่มระบบ
    if (this._auth.accessToken)
      this._auth.initalizeLoadUserLogin().subscribe();
  }

  ngOnInit() {
    this._primeNg.ripple = true;
  }
}

import { ApiPathURL, AppURL } from "../app.url";
import { AccountURL } from "../modules/account/account.url";
import { AuthenDashboardURL } from "../modules/authen-dashboard/authen-dashboard.url";
import { AuthenPosURL } from "../modules/authen-pos/authen-pos.url";
import { AuthenURL } from "../modules/authen/authen.url";

export class AppConfig {
    /** api path of microservice url */
    ApiPathURL = ApiPathURL;

    /**  root url */
    AppURL = AppURL;

    /** account url */
    AccountURL = AccountURL;

    /** account url */
    AuthenURL = AuthenURL;

    /** dashboard url */
    AuthenDashboardURL = AuthenDashboardURL;

    /** pos url */
    AuthenPosURL = AuthenPosURL;
}